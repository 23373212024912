import { Button, message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { db } from "utils/firebase";

export const Settings = () => {
  const [loading, setLoading] = React.useState(true);
  const [prompt, setPrompt] = React.useState(null);

  useEffect(() => {
    (async () => {
      const data = await db.doc(`settings/test`).get();

      let prompt = "";

      if (data.exists && data.data().prompt) {
        prompt = data.data().prompt;
      }
      setPrompt(prompt);
      setLoading(false);
    })();
  }, []);

  const handleSave = async () => {
    setLoading(true);

    try {
      await db.doc(`settings/test`).set({ prompt });
      message.success("Saved");
    } catch (e) {
      message.error("Error: ", e?.message);
    }

    setLoading(false);
  };

  if (loading && prompt === null) {
    return <Spin />;
  }

  return (
    <div style={{ padding: 16 }}>
      <div>Prompt:</div>
      <TextArea value={prompt} onChange={(e) => setPrompt(e.target.value)} />
      <Button onClick={handleSave} loading={loading}>
        Save
      </Button>
    </div>
  );
};
