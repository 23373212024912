import React, { useState } from "react";

import { Form, Input, Button, Row, Col, message, Checkbox } from "antd";

import { db } from "utils/firebase";
import firebase from "firebase";

import "./styles.less";

const { REACT_APP_TENANT_ID } = process.env;

const UserUpdateForm = ({ user, onCancel, onCallback, setUsers }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Form
      form={form}
      layout="vertical"
      name="userUpdateForm"
      onFinish={(values) => {
        setLoading(true);

        let userUpdate = {
          name: values?.name,
          updatedAt: firebase.firestore.Timestamp.now(),
          dicts: [],
          phone: values?.phone || "",
          address: values?.address || "",
          isActive: values?.isActive,
          tenantId: REACT_APP_TENANT_ID,
        };

        db.collection("users")
          .doc(user?.id)
          .update(userUpdate)
          .then((doc) => {
            setLoading(false);
            message.success("User has been update successfully");
            onCallback();
          })
          .catch((err) => {
            setLoading(false);
            message.error(err.code);
          });
      }}
      initialValues={user}
    >
      <div className="user-form">
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item name="phone" label={"Phone"}>
              <Input placeholder={"0987675234"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label={"Address"}>
              <Input
                placeholder={
                  "CC Hoàng Sa, Đường D1, Phường An Thuận Động, Q7, HCM"
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Button type="clear" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Row>
      </div>
    </Form>
  );
};

export default UserUpdateForm;
