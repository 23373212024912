import React, { useState, useRef, useEffect } from "react";
import {
  Layout,
  Card,
  Row,
  Col,
  Button,
  Spin,
  Input,
  InputNumber,
  Form,
  message,
} from "antd";
import "./styles.less";
import {
  PlayCircleFilled,
  PauseCircleFilled,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import useRecorder from "./useRecorder";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import * as uuid from "uuid";
import moment from "moment-timezone";
import { db, storage } from "utils/firebase";
import useAuth from "hooks/useAuth";
import { has } from "lodash";
import loading from "assets/loading.gif";

const socketViUrl =
  "wss://quangbd.namitech.ai/connect?sample_rate=16000&analysis=false&with_scd=true";

const StreamingTranscript = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { user } = useAuth();

  const [messageHistory, setMessageHistory] = useState([]);

  const socket = useRef(null);

  let [, startRecording, stopRecording, audioURL, audioBlob] =
    useRecorder(socket);

  const [startRec, setStartRec] = useState(false); // true: recording, false: not recording, null: loading

  const [connectionStatus, setConnectionStatus] = useState("not_connected"); // connected, not_connected, connecting, error
  const [connectedAt, setConnectedAt] = useState();
  const [serverStatus, setServerStatus] = useState("loading"); // loading, live, down
  const [lastServerStatusCheckAt, setLastServerStatusCheckAt] = useState();
  const [stoppedAt, setStoppedAt] = useState();
  const [speaking, setSpeaking] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const timeoutRef = useRef(null);

  const handleUpload = async (values) => {
    if (isUploading) return;
    setIsUploading(true);
    try {
      const { fileName, numberOfSpeakers } = values;

      // Upload audio
      const fileUUID = uuid.v4();
      const createdAt = moment();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`${fileUUID}.webm`);

      const uploadTask = await fileRef.put(audioBlob);
      const url = await uploadTask.ref.getDownloadURL();

      const payload = {
        uuid: fileUUID,
        name: fileName,
        user: user?.uid,
        length: 0,
        createdAt: createdAt.toDate(),
        lastEdit: createdAt.toDate(),
        url,
        status: "UPLOADED",
        tenantId: process.env.REACT_APP_TENANT_ID,
        source: "saas",
        numberOfSpeakers,
      };

      const streamingPayload = {
        name: fileName + " (streaming)",
        user: user?.uid,
        length: (stoppedAt.getTime() - connectedAt.getTime()) / 1000,
        createdAt: createdAt.toDate(),
        lastEdit: createdAt.toDate(),
        url,
        status: "TRANSCRIBED",
        tenantId: process.env.REACT_APP_TENANT_ID,
        source: "saas",
        numberOfSpeakers,
      };

      const streamingRef = db.collection("files").doc();

      await streamingRef.set(streamingPayload);

      await db.runTransaction(async (transaction) => {
        messageHistory.forEach((message, i) => {
          const id = i.toString().padStart(6, "0");
          const sentenceRef = streamingRef.collection("sentences").doc(id);
          transaction.set(sentenceRef, {
            rawData: message,
            start: message.start,
            end: message.end,
            speaker: message.label,
            transcript: message.transcript_norm,
            conf: message.conf_avg,
            words: message.words.map((word) => ({
              ...word,
              start: word.start + message.start,
              end: word.end + message.start,
            })),
          });
        });
      });

      await db.collection("files").doc(fileUUID).set(payload);
      message.success("Tải file ghi âm thành công!");
    } catch (e) {
      console.log(e);
      message.error("Tải file ghi âm thất bại!");
    } finally {
      setIsUploading(false);
    }
  };

  const duration =
    stoppedAt && connectedAt
      ? Math.floor(
          ((stoppedAt.getTime() - connectedAt.getTime()) / 1000) * 100
        ) / 100
      : null;

  const endRef = useRef(null);

  const startRecord = () => {
    if (startRec) {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }

      setStartRec(false);
      stopRecording();
      setStoppedAt(new Date());
    } else {
      setConnectionStatus("connecting");
      // Full context socket
      socket.current = new WebSocket(socketViUrl);

      socket.current.addEventListener("open", () => {
        setConnectionStatus("connected");
        setConnectedAt(new Date());
        startRecording();

        setStartRec(true);
      });

      socket.current.addEventListener("close", () => {
        setConnectionStatus("not_connected");
        setSpeaking(false);
      });

      socket.current.addEventListener("error", () => {
        setConnectionStatus("error");
      });

      socket.current.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (has(data, "speaking")) {
          setSpeaking(data.speaking);
        } else {
          setMessageHistory((prev) => prev.concat(data));
          if (endRef.current) {
            // Check if element is in the view
            const rect = endRef.current.getBoundingClientRect();
            if (
              rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <=
                (window.innerWidth || document.documentElement.clientWidth)
            ) {
              endRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
      });
      setMessageHistory([]);
    }
  };

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const resp = await fetch("https://quangbd.namitech.ai/ping");
        if (resp.ok) {
          setServerStatus("live");
        } else {
          setServerStatus("down");
        }
        setLastServerStatusCheckAt(new Date());
      } catch (e) {
        setServerStatus("down");
        setLastServerStatusCheckAt(new Date());
      }
      timeoutRef.current = setTimeout(checkServerStatus, 5000);
    };
    checkServerStatus();

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24 }}>
        <Card style={{ marginBottom: 18 }} bordered={false}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              marginBottom: 4,
            }}
          >
            Tình trạng máy chủ:
            <span
              style={{
                color: serverStatus === "live" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {serverStatus === "live" && "Đang hoạt động"}
              {serverStatus === "down" && "Đang bảo trì"}
              {serverStatus === "loading" && <Spin />}
            </span>
          </div>
          <div style={{ marginBottom: 8 }}>
            Lần kiểm tra cuối:{" "}
            <strong>
              {lastServerStatusCheckAt
                ? new Date(lastServerStatusCheckAt).toLocaleTimeString()
                : "N/A"}
            </strong>
          </div>
          <Card>
            <p style={{ textAlign: "center" }}>
              {startRec ? (
                <a onClick={startRecord}>
                  <PauseCircleFilled
                    style={{ fontSize: 24, color: "#BD271E" }}
                  />
                  <span
                    style={{ fontSize: 24, color: "#BD271E", marginLeft: 10 }}
                  >
                    {lang.btnStop}
                  </span>
                </a>
              ) : (
                <a onClick={startRecord}>
                  <PlayCircleFilled
                    style={{ fontSize: 24, color: "#BD271E" }}
                  />
                  <span
                    style={{
                      fontSize: 24,
                      color: "#dBD271E",
                      marginLeft: 10,
                    }}
                  >
                    {lang.btnStart}
                  </span>
                </a>
              )}
              <br />
            </p>
          </Card>
        </Card>
        <Row style={{ paddingBottom: "24px" }}>
          <Col span={24}>
            <Card style={{ height: "100%" }}>
              <p>
                {lang.connectionStatus}:{" "}
                {connectionStatus === "connected" ? (
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    {lang.connected}
                  </span>
                ) : connectionStatus === "not_connected" ? (
                  <span style={{ fontWeight: "bold", color: "red" }}>
                    Không kết nối
                  </span>
                ) : connectionStatus === "error" ? (
                  <span style={{ fontWeight: "bold", color: "red" }}>
                    Kết nối bị lỗi, vui lòng thử lại
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold" }}>Đang kết nối</span>
                )}
              </p>
              <p>
                Thời gian bắt đầu:{" "}
                <strong>
                  {connectedAt && (connectionStatus === "connected" || audioURL)
                    ? new Date(connectedAt).toLocaleTimeString()
                    : "N/A"}
                </strong>
              </p>
              {audioURL && (
                <>
                  <p>
                    Thời gian kết thúc:{" "}
                    <strong>{stoppedAt?.toLocaleTimeString() || "N/A"}</strong>
                  </p>
                  <p>
                    Thời lượng:{" "}
                    <strong>{duration ? `${duration} giây` : "N/A"}</strong>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 12,
                    }}
                  >
                    <audio controls src={audioURL} style={{ flex: 1 }} />
                    <Button
                      onClick={() => {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = audioURL;
                        a.download = "audio_data.webm";
                        a.click();
                      }}
                      type="link"
                    >
                      <DownloadOutlined style={{ fontSize: "150%" }} />
                    </Button>
                  </div>
                  <Form
                    style={{ marginBottom: 24 }}
                    initialValues={{ fileName: "", numberOfSpeakers: 1 }}
                    layout="inline"
                    autoComplete="off"
                    onFinish={handleUpload}
                  >
                    <Form.Item
                      label="Tên cuộc họp"
                      name="fileName"
                      rules={[
                        {
                          required: true,
                          message: "Xin hãy nhập tên cuộc họp!",
                        },
                      ]}
                    >
                      <Input style={{ width: 240 }} />
                    </Form.Item>
                    <Form.Item
                      label="Số lượng đại biểu có phát biểu trong cuộc họp"
                      name="numberOfSpeakers"
                      rules={[
                        {
                          required: true,
                          message: "Xin hãy nhập số lượng người nói!",
                        },
                      ]}
                    >
                      <InputNumber precision={0} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
                      <Button
                        loading={isUploading}
                        type="primary"
                        htmlType="submit"
                      >
                        Tải file ghi âm
                      </Button>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2, span: 4 }}>
                      <Button type="primary" htmlType="button">
                        Khai báo mẫu giọng đại biểu tham gia cuộc họp
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
              <Card>
                {messageHistory.map((message, index) => {
                  return (
                    <div key={index} style={{ marginBottom: 24 }}>
                      <div>
                        <UserOutlined />{" "}
                        {new Date(
                          message.start * 1000 + connectedAt.getTime()
                        ).toLocaleTimeString()}
                        {" - "}
                        {new Date(
                          message.end * 1000 + connectedAt.getTime()
                        ).toLocaleTimeString()}
                      </div>
                      <div>{message.transcript_norm}</div>
                    </div>
                  );
                })}
                {speaking && (
                  <div>
                    <span>
                      <img
                        src={loading}
                        alt="loading"
                        style={{ height: "8px" }}
                      />
                    </span>
                  </div>
                )}
                <div ref={endRef} />
              </Card>
            </Card>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

export default StreamingTranscript;
