import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Button, Col, Dropdown, Form, Input, Menu, Row, message } from "antd";
import bgImg from "assets/background.svg";
import { useEffect, useState } from "react";
import { auth, db } from "utils/firebase";
import "./styles.less";

import { useNavigate } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { localeAtom, userAtom } from "recoils/atoms";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import logo from "assets/Color2.png";
import iconFlagJa from "assets/icons/japan-flag.png";
import iconFlagEn from "assets/icons/united-states-flag.png";
import iconFlagVi from "assets/icons/vietnam-flag.png";
import useAuth from "hooks/useAuth";
import _ from "lodash";
import { IS_FUNIX } from "utils/constants";

const { REACT_APP_TENANT_ID, REACT_APP_DEFAULT_LANG } = process.env;

const Login = () => {
  const [locale, setLocale] = useRecoilState(localeAtom);

  const navigate = useNavigate();
  const { user } = useAuth();

  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/user/transcripts");
    }
  }, [user, navigate]);

  const onFinish = (values) => {
    setLoading(true);

    auth.tenantId = REACT_APP_TENANT_ID;
    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        await db
          .doc(`users/${user.uid}`)
          .get()
          .then((doc) => {
            const userData = doc.data();
            if (!doc.exists || doc.data().tenantId !== REACT_APP_TENANT_ID) {
              message.error("User is not found");
              setLoading(false);
            } else {
              setLoading(false);
              if (!_.isUndefined(userData.isActive) && !userData.isActive) {
                auth.signOut();
                return;
              }
              navigate(`/user/transcripts`);
            }
          })
          .catch((err) => {
            message.error(err.code);
            setLoading(false);
          });
      })
      .catch((error) => {
        const errorCode = error.code;

        if (
          errorCode === "auth/wrong-password" ||
          errorCode === "auth/user-not-found"
        ) {
          message.error(lang.wrongEmailPassword);
        } else if (errorCode === "auth/invalid-email") {
          message.error(lang.wrongEmailFormat);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (locale === "null") {
      setLocale(REACT_APP_DEFAULT_LANG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (locale) => {
    setLocale(locale);
  };

  const languages = (
    <Menu>
      <Menu.Item onClick={() => changeLanguage("ja")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagJa} width={24} alt="Japanese Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>日本語</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage("en")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagEn} width={24} alt="English Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>English</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage("vi")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagVi} width={24} alt="Vietnam Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Tiếng Việt</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const languageSelected = (locale) => {
    switch (locale) {
      case "vi":
        return <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
      case "en":
        return <img src={iconFlagEn} width={30} alt="English Language" />;
      case "ja":
        return <img src={iconFlagJa} width={30} alt="Japanese Language" />;
      default:
        return <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
    }
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{ alignSelf: "center" }}
          className="paddingCol"
        >
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 40 }} />
            <h3
              className="login-title"
              style={{ marginTop: 40, marginBottom: 40 }}
            >
              <strong>{lang.user}</strong> {lang.login}
            </h3>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={lang.email}
                name="email"
                rules={[{ required: true, message: lang.requiredEmail }]}
              >
                <Input
                  size={"large"}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={lang.emailRequirePlaceholder}
                />
              </Form.Item>
              <Form.Item
                label={lang.password}
                name="password"
                rules={[{ required: true, message: lang.requiredPassword }]}
              >
                <Input.Password
                  size={"large"}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={lang.placeholderPassword}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: "black" }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: "black" }} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button
                    size={"large"}
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button"
                  >
                    {lang.login}
                  </Button>
                ) : (
                  <Button
                    size={"large"}
                    type="primary"
                    loading={loading}
                    block
                    className="login-form-button"
                  >
                    {lang.loading}
                  </Button>
                )}
              </Form.Item>
              <Form.Item>
                <p style={{ textAlign: "right" }}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/forgot-password");
                    }}
                  >
                    {lang.forgotPassword}
                  </a>
                </p>
              </Form.Item>
            </Form>
          </div>
          <div style={{ position: "relative" }}>
            <Dropdown overlay={languages} placement="bottom">
              <div
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  marginRight: 16,
                  width: 100,
                }}
              >
                {languageSelected(locale)}
                <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
              </div>
            </Dropdown>
          </div>
          <div style={{ paddingTop: "25vh" }}>{lang.slogan}</div>
          <div style={{ paddingTop: "3vh", fontWeight: "bold" }}>
            {lang.companyRights}
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{
            backgroundImage: `url(${bgImg})`,
            width: "100%",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        ></Col>
      </Row>
    </div>
  );
};

export default Login;
