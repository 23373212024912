import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//lang
import moment from "moment-timezone";
import "moment/locale/vi";
import { useTranslation } from "react-i18next";

import { useRecoilState } from "recoil";
import { adminAtom, userAtom } from "recoils/atoms";

import AdminLogin from "pages/Home/AdminLogin";
import UserLogin from "pages/Home/UserLogin";
import UserForgotPassword from "pages/Home/UserForgotPassword";
import UserStreamingTranscript from "pages/User/StreamingTranscript";

//Private - Admin
import AdminLayout from "components/web/Layout/Admin";
import Users from "pages/Admin/Users";

import UserLayout from "components/web/Layout/User";
import SaaSTranscripts from "pages/User/SaaSTranscripts";

import { ConfigProvider } from "antd";
import UserResetPassword from "pages/Home/UserResetPassword";
import MeetingDetail from "pages/User/MeetingDetail";
import { auth, db } from "utils/firebase";
import { message } from "antd";
import _ from "lodash";
import { AuthContext } from "hooks/useAuth";
import { Settings } from "pages/User/Settings/Settings";

const { REACT_APP_TENANT_ID, REACT_APP_SITENAME } = process.env;

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("vi");
    moment.locale("vi");
  }, []);

  const isAdminRoute = window.location.pathname
    .split("/")[1]
    .startsWith("admin");
  const [user, setUser] = useRecoilState(isAdminRoute ? adminAtom : userAtom);
  const [token, setToken] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // User Login
      if (!isAdminRoute && user && user.tenantId === REACT_APP_TENANT_ID) {
        user.getIdToken(true).then(async (token) => {
          //get data
          await db
            .doc(`users/${user.uid}`)
            .get()
            .then((doc) => {
              if (!doc.exists) {
                auth.signOut();
                return;
              }
              const userData = doc.data();
              if (!_.isUndefined(userData.isActive) && !userData.isActive) {
                message.error("Account deactivated");
                auth.signOut();
                return;
              }
              setUser({
                uid: user?.uid,
                email: user?.providerData[0]?.uid,
                profile: doc.data(),
                tenantId: user?.tenantId,
              });
              setToken(token);
              setLoadingAuth(false);
            })
            .catch((err) => {
              setUser({
                uid: user?.uid,
                email: user?.providerData[0]?.uid,
              });
              setToken(token);
              setLoadingAuth(false);
            });
        });
      }
      // Admin Login
      else if (isAdminRoute && user && !user.tenantId) {
        db.doc(`tenants/${user.uid}`)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              return;
            } else {
              const tenantId = doc.data()?.tenantId;
              if (tenantId !== REACT_APP_TENANT_ID) {
                auth.signOut();
                setLoadingAuth(false);
                window.location.replace("/admin-login");
              }
              setUser({
                uid: user?.uid,
                email: user?.providerData[0]?.uid,
                tenantId: doc?.data()?.tenantId,
              });
              setLoadingAuth(false);
            }
          });
      } else {
        setLoadingAuth(false);
        setUser(null);
        if (window.location.pathname.split("/")[1] === "admin")
          window.location.replace("/admin-login");
        else if (window.location.pathname.split("/")[1] === "user")
          window.location.replace("/");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, token, loadingAuth, setLoadingAuth }}>
      <ConfigProvider locale="vi">
        <BrowserRouter>
          <HelmetProvider>
            <Helmet>
              <title>{REACT_APP_SITENAME}</title>
            </Helmet>
            <Routes>
              <Route path="/" element={<UserLogin />} />
              <Route path="/forgot-password" element={<UserForgotPassword />} />
              <Route path="/reset/:token" element={<UserResetPassword />} />

              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="admin" element={<AdminLayout />}>
                <Route path="/" element={<Navigate to="users" />} />
                <Route path="users" element={<Users />} />
              </Route>
              <Route path="user" element={<UserLayout />}>
                <Route path="record" element={<UserStreamingTranscript />} />
                <Route path="transcripts" element={<SaaSTranscripts />} />
                <Route path="transcripts/:fileId" element={<MeetingDetail />} />
                <Route path="settings" element={<Settings />} />
              </Route>
            </Routes>
          </HelmetProvider>
        </BrowserRouter>
      </ConfigProvider>
    </AuthContext.Provider>
  );
};

export default App;
