import React, { useEffect, useRef, useState } from "react";
import {
  CheckOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  StepForwardOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { Popover, Slider } from "antd";
import Text from "antd/lib/typography/Text";
import { changeTime } from "components/TranscriptEditor/util";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import { convertToDatetime, formatTime } from "utils/utils";
import { Wave } from "@foobar404/wave";

// Check url return true if video, false if audio
const isVideoUrl = (url) => {
  // match regex for video from any url
  const regex = /(.*?).(mp4|webm|ogg|ogv|avi|wmv|mov|flv|3gp|mkv|mpeg|mpg)/g;
  return regex.test(url);
};

const stateDefault = {
  playing: false,
  volume: 0.8,
  muted: false,
  played: 0,
  duration: 0,
  playedString: "",
  durationString: "",
  loaded: 0,
  playbackRate: 1.0,
};

const MediaPanel = React.forwardRef(({ url, playTo, duration }, mediaRef) => {
  const [videoState, setVideoState] = useState(stateDefault);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const canvasRef = useRef(null);

  useEffect(() => {
    const media = mediaRef.current;

    if (media && url) {
      if (media.readyState === 0) {
        media.load();
      }

      const handleTimeUpdate = () => {
        if (!media?.seeking) {
          const cappedTime = Math.min(media.currentTime, duration);
          if (media.currentTime > duration) {
            media.pause();
          }
          if (playTo.current && cappedTime >= playTo.current) {
            media.pause();
            playTo.current = null;
          }
          const time = formatTime(cappedTime);
          setVideoState((e) => ({
            ...e,
            played: cappedTime,
            playedString: `${time.hours !== "00" ? `${time.hours}:` : ""}${
              time.minutes
            }:${time.seconds}.${time.milliseconds ?? "00"}`,
          }));
        }
      };

      if (media) media.addEventListener("timeupdate", handleTimeUpdate);

      if (mediaRef.current && canvasRef.current) {
        const temp = new Wave(mediaRef.current, canvasRef.current);

        temp.addAnimation(
          new temp.animations.Lines({
            fillColor: "#4a90e2",
            lineColor: "#4a90e2",
            center: true,
            mirroredY: true,
          })
        );
      }

      return () => {
        media.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [url]);

  if (!url) {
    return null;
  }

  const isVideo = isVideoUrl(url);

  const handlePlayPause = () => {
    if (
      mediaRef.current &&
      (mediaRef.current?.paused || mediaRef.current?.ended)
    ) {
      mediaRef.current.play();
    } else {
      mediaRef.current.pause();
    }
  };

  const handleSeekChange = (value) => {
    const timeInSeconds = value / 10000000;
    const time = formatTime(timeInSeconds);
    if (mediaRef.current) mediaRef.current.pause();
    setVideoState((e) => ({
      ...e,
      played: timeInSeconds,
      playedString: `${time.hours !== "00" ? `${time.hours}:` : ""}${
        time.minutes
      }:${time.seconds}.${time.milliseconds ?? "00"}`,
    }));
  };

  const jumpContent = (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const dataObj = Object.fromEntries(data.entries());
        const { hours, minutes, seconds } = dataObj;
        const timeInSeconds = +hours * 3600 + +minutes * 60 + +seconds;
        changeTime(mediaRef.current, timeInSeconds);
        playTo.current = null;
      }}
    >
      <div className="jump-content-container">
        <div className="jump-content-input-container">
          <p>{lang.hour}</p>
          <input name="hours" type="number" min="0" />
        </div>
        <div className="jump-content-input-container">
          <p>{lang.minute}</p>
          <input name="minutes" type="number" min="0" max="59" />
        </div>
        <div className="jump-content-input-container">
          <p>{lang.second}</p>
          <input name="seconds" type="number" min="0" max="59" />
        </div>
        <button className="ant-btn-primary" type="submit">
          <CheckOutlined />
          <p>{lang.apply}</p>
        </button>
      </div>
    </form>
  );

  const volumeContent = (
    <Slider
      min={0}
      max={10}
      onChange={(value) => {
        setVideoState((e) => ({
          ...e,
          volume: value / 10,
        }));
        if (mediaRef.current?.muted) {
          mediaRef.current.muted = false;
        }
        mediaRef.current.volume = value / 10;
      }}
      value={videoState.volume * 10}
    />
  );

  const handleSeekMouseUp = (value) => {
    changeTime(mediaRef.current, value / 10000000);
    playTo.current = null;
  };

  useEffect(() => {
    const time = formatTime(duration);
    setVideoState((e) => ({
      ...e,
      duration,
      durationString: `${time.hours !== "00" ? `${time.hours}:` : ""}${
        time.minutes
      }:${time.seconds}`,
    }));
  }, []);

  return (
    <div className="edit-video-wrapper">
      <div className="edit-video">
        {isVideo ? (
          <video
            onClick={handlePlayPause}
            onPause={() => setVideoState((e) => ({ ...e, playing: false }))}
            onPlay={() => setVideoState((e) => ({ ...e, playing: true }))}
            ref={mediaRef}
            className="edit-video-player"
            src={url}
            preload="metadata"
            crossOrigin="anonymous"
          />
        ) : (
          <>
            <audio
              onPause={() => setVideoState((e) => ({ ...e, playing: false }))}
              onPlay={() => setVideoState((e) => ({ ...e, playing: true }))}
              ref={mediaRef}
              className="edit-video-player"
              src={url}
              preload="metadata"
              crossOrigin="anonymous"
            />
            <canvas
              onClick={handlePlayPause}
              ref={canvasRef}
              style={{ height: "160px", margin: 8 }}
            />
          </>
        )}
      </div>
      <div className="edit-video-progress">
        <Slider
          min={0}
          max={videoState.duration * 10000000}
          tooltipVisible={false}
          value={videoState.played * 10000000}
          onChange={handleSeekChange}
          onAfterChange={handleSeekMouseUp}
          className="edit-video-progress-slider"
        />
        <div className="time">
          <Text type="secondary">
            <time
              id="time-elapsed"
              dateTime={convertToDatetime(videoState.playedString)}
            >
              {videoState.playedString ? videoState.playedString : "00:00"}
            </time>
            <span> / </span>
            <time
              id="duration"
              dateTime={convertToDatetime(videoState.durationString)}
            >
              {videoState.durationString ? videoState.durationString : "00:00"}
            </time>
          </Text>
        </div>
      </div>
      <div className="edit-video-btn">
        <div className="edit-video-btn-item" onClick={handlePlayPause}>
          <span className="edit-video-btn-item-icon">
            {videoState.playing ? <PauseOutlined /> : <PlayCircleOutlined />}
          </span>
          <span className="edit-video-btn-item-text">
            {videoState.playing ? lang.pause : lang.play}
          </span>
        </div>

        <Popover content={jumpContent} title={lang.jumpToTimestamp}>
          <div className="edit-video-btn-item">
            <span className="edit-video-btn-item-icon">
              <StepForwardOutlined />
            </span>
            <span className="edit-video-btn-item-text">{lang.jumpTo}</span>
          </div>
        </Popover>

        <div
          className="edit-video-btn-item"
          onClick={(e) => {
            let playbackRate = videoState.playbackRate;

            if (e.shiftKey) {
              if (playbackRate > 1) {
                playbackRate = 0.75;
              } else if (playbackRate === 0.25) {
                playbackRate = 1;
              } else {
                playbackRate -= 0.25;
              }
            } else {
              if (playbackRate < 1) {
                playbackRate = 1;
              } else if (playbackRate === 3) {
                playbackRate = 1;
              } else {
                playbackRate += 0.5;
              }
            }

            setVideoState((e) => ({
              ...e,
              playbackRate,
            }));
            mediaRef.current.playbackRate = playbackRate;
          }}
        >
          <span className="edit-video-btn-item-icon">
            <Text className="edit-video-btn-item-icon-speed anticon">
              {videoState.playbackRate}x
            </Text>
          </span>
          <span className="edit-video-btn-item-text">{lang.speed}</span>
        </div>

        <Popover content={volumeContent} title={lang.setVolume}>
          <div className="edit-video-btn-item">
            <span className="edit-video-btn-item-icon">
              <WifiOutlined rotate={90} />
            </span>
            <span className="edit-video-btn-item-text">{lang.volume}</span>
          </div>
        </Popover>
      </div>
    </div>
  );
});

export default React.memo(MediaPanel);
