import { IS_FUNIX, STATUS_BLACKLIST } from "./constants";
import _isEqual from "lodash/isEqual";
import XRegExp from "xregexp";
import _, { isEmpty, isEqual, xorWith } from "lodash";
import csvDownload from "json-to-csv-export";

export const convertToDatetime = (time) => {
  return time ? `${time.split(":")[0]}m ${time.split(":")[1]}s` : "00m 00s";
};

export const formatTime = (timeInSeconds) => {
  if (!timeInSeconds) {
    return { hours: "00", minutes: "00", seconds: "00", milliseconds: "00" };
  }
  const hours = Math.floor(timeInSeconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = (Math.floor(timeInSeconds / 60) % 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.trunc(timeInSeconds % 60)
    .toString()
    .padStart(2, "0");
  const milliseconds = timeInSeconds
    .toFixed(3)
    .split(".")[1]
    ?.replace(/0+$/, "");
  return { hours, minutes, seconds, milliseconds };
};

export const formatDateTime = (timeInSeconds, withDate = false) => {
  const t = new Date(timeInSeconds * 1000);

  return t.toLocaleTimeString("ja-JP", {
    timeZone: "Asia/Tokyo",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    ...(withDate ? { year: "numeric", month: "2-digit", day: "2-digit" } : {}),
  });
};

export const formatTimeToString = (timeInSeconds, withMilliseconds = false) => {
  const t = formatTime(timeInSeconds);

  return t.hours === "00"
    ? `${t.minutes}:${t.seconds}${
        t.milliseconds && withMilliseconds ? `.${t.milliseconds}` : ""
      }`
    : `${t.hours}:${t.minutes}:${t.seconds}${
        t.milliseconds && withMilliseconds ? `.${t.milliseconds}` : ""
      }`;
};

export function jsonParse(str) {
  let dataParsed;
  try {
    dataParsed = JSON.parse(str);
  } catch (e) {
    return {};
  }
  return dataParsed;
}

export const exportToJsonFile = (jsonData, fileName) => {
  const dataStr = JSON.stringify(jsonData, null, "\t");
  const dataUri =
    "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

  if (!fileName.endsWith(".json")) {
    fileName = fileName + ".json";
  }

  const linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", fileName);
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};

export const exportToCSVFile = (jsonData, fileName) => {
  const data = jsonData.map((sentence) => ({
    start: sentence.start,
    speaker: sentence.speaker,
    transcript: sentence.transcript,
  }));

  csvDownload({
    data,
    filename: fileName,
    headers: ["Start", "Speaker", "Transcript"],
  });

  // const dataUri =
  //   "data:text/csv;charset=utf-8,%EF%BB%BF" +
  //     jsonData
  //       .map(
  //         (sentence) => `
  // ${Object.values(formatTime(sentence.start)).join(":")},${sentence.speaker},${
  //           sentence.transcript
  //         }`
  //       )
  //   );

  // const linkElement = document.createElement("a");
  // linkElement.setAttribute("href", dataUri);
  // linkElement.setAttribute("download", fileName);
  // document.body.appendChild(linkElement);
  // linkElement.click();
  // document.body.removeChild(linkElement);
};

export function getUrlExtension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

function secondsToFormattedTime(milliseconds) {
  // Calculate hours, minutes, seconds, and milliseconds
  const hours = Math.floor(milliseconds / 3600000);
  milliseconds %= 3600000;
  const minutes = Math.floor(milliseconds / 60000);
  milliseconds %= 60000;
  const seconds = Math.floor(milliseconds / 1000);
  milliseconds %= 1000;

  // Format the components with leading zeros
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  const formattedMilliseconds = String(milliseconds).padStart(3, "0");

  // Create the formatted string
  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds},${formattedMilliseconds}`;

  return formattedTime;
}

export const exportToSRTFile = (sentences, fileName) => {
  const data = sentences
    .map(
      (sentence, index) =>
        `${index + 1}\n${secondsToFormattedTime(
          sentence.start * 1000
        )} --> ${secondsToFormattedTime(sentence.end * 1000)}\n${
          sentence.transcript
        }`
    )
    .join("\n\n");

  let encodedUri = "data:text/plain;charset=utf-8,%EF%BB%BF" + encodeURI(data);

  // return;
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".srt");
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};

export const exportToSRTWithSpeakerFile = (sentences, fileName) => {
  const data = sentences
    .map(
      (sentence, index) =>
        `${index + 1}\n${secondsToFormattedTime(
          sentence.start * 1000
        )} --> ${secondsToFormattedTime(sentence.end * 1000)}\n${
          sentence.speaker
        }: ${sentence.transcript}`
    )
    .join("\n\n");

  let encodedUri = "data:text/plain;charset=utf-8,%EF%BB%BF" + encodeURI(data);

  // return;
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".srt");
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};

export const exportToTxtFile = (sentences, fileName) => {
  const data = sentences
    .map((sentence) => `${speakerToString(sentence.speaker)}: ${sentence.transcript}`)
    .join("\n");

  let encodedUri = "data:text/plain;charset=utf-8,%EF%BB%BF" + encodeURI(data);

  // return;
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".txt");
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};

export const exportDataTimestamp = (sentences, fileName) => {
  const data = [];

  sentences = sentences.map((sentence) => ({
    speaker: sentence.speaker,
    start: sentence.start,
    end: sentence.end,
  }));

  sentences.forEach((sentence, index) => {
    if (index === 0) {
      data.push(sentence);
    } else {
      const lastSentence = _.last(data);
      if (lastSentence.speaker === sentence.speaker) {
        lastSentence.end = sentence.end;
      } else {
        data.push(sentence);
      }
    }
  });

  const dataStr = JSON.stringify(data, null, "\t");
  const dataUri =
    "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

  const linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", "timestamp_" + fileName);
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};

export const exportToTextFile = (
  data,
  fileName,
  exportAll,
  transStatusLocale,
  locale
) => {
  let line = "";
  let oldPar = null;
  const lines = [...data, { speaker: -1 }].map((paragraph, index) => {
    if (paragraph.transcript === "") {
      return null;
    } else {
      const localLine = line;
      const localPar = oldPar;
      line = paragraph.transcript;
      oldPar = paragraph;
      if (!localPar || (!exportAll && !localPar.checked && localPar.speaker))
        return null;
      const time = Object.values(formatTime(localPar.start)).join(":");
      const content =
        localPar.speaker !== (null || undefined)
          ? `${localLine}`
          : `${
              localPar.status === "on_transcription"
                ? transStatusLocale.started
                : localPar.status === "off_transcription"
                ? transStatusLocale.stopped
                : transStatusLocale.close
            }`;
      return [
        time,
        localPar.speaker !== (null || undefined) ? localPar.speaker : "",
        content,
      ];
    }
  });

  let encodedUri =
    "data:text/csv;charset=utf-8,%EF%BB%BF" +
    encodeURI(
      lines
        .filter((l) => l)
        .map((e) => e.map((item) => `"${item}"`).join(","))
        .join("\n")
    );
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
};

export const filterSentences = (sentences) => {
  return sentences.filter(
    (s) =>
      !STATUS_BLACKLIST.includes(s.status) &&
      (!s.speaker || (s.speaker && s.transcript !== ""))
  );
};

export const isEqualAllProps = (
  prevProps,
  nextProps,
  specificPropHandle = {}
) => {
  const keys = new Set([...Object.keys(prevProps), ...Object.keys(nextProps)]);
  const specificKeys = Object.keys(specificPropHandle);
  return ![...keys].some((k) => {
    const prev = prevProps[k];
    const next = nextProps[k];
    if (specificKeys.includes(k)) {
      return specificPropHandle[k](prev, next);
    }
    return !_isEqual(prev, next);
  });
};

export const speakerToString = (s) => {
  s = s.toString().trim();
  if (IS_FUNIX) {
    return s === "1" ? "Agent" : "Khách";
  }
  return !isNaN(s) ? `Đại biểu ${s}` : s;
};

(function (XRegExp) {
  function prepareLb(lb) {
    // Allow mode modifier before lookbehind
    let parts = /^((?:\(\?[\w$]+\))?)\(\?<([=!])([\s\S]*)\)$/.exec(lb);
    return {
      // $(?!\s) allows use of (?m) in lookbehind
      lb: XRegExp(parts ? parts[1] + "(?:" + parts[3] + ")$(?!\\s)" : lb),
      // Positive or negative lookbehind. Use positive if no lookbehind group
      type: parts ? parts[2] === "=" : !parts,
    };
  }

  XRegExp.execLb = function (str, lb, regex) {
    let pos = 0,
      match,
      leftContext;
    lb = prepareLb(lb);
    while ((match = XRegExp.exec(str, regex, pos))) {
      leftContext = str.slice(0, match.index);
      if (lb.type === lb.lb.test(leftContext)) {
        return match;
      }
      pos = match.index + 1;
    }
    return null;
  };

  XRegExp.testLb = function (str, lb, regex) {
    return !!XRegExp.execLb(str, lb, regex);
  };

  XRegExp.searchLb = function (str, lb, regex) {
    let match = XRegExp.execLb(str, lb, regex);
    return match ? match.index : -1;
  };

  XRegExp.matchAllLb = function (str, lb, regex) {
    let matches = [],
      pos = 0,
      match,
      leftContext;
    lb = prepareLb(lb);
    while ((match = XRegExp.exec(str, regex, pos))) {
      leftContext = str.slice(0, match.index);
      if (lb.type === lb.lb.test(leftContext)) {
        matches.push(match[0]);
        pos = match.index + (match[0].length || 1);
      } else {
        pos = match.index + 1;
      }
    }
    return matches;
  };

  XRegExp.replaceLb = function (str, lb, regex, replacement) {
    let output = "",
      pos = 0,
      lastEnd = 0,
      match,
      leftContext;
    lb = prepareLb(lb);
    while ((match = XRegExp.exec(str, regex, pos))) {
      leftContext = str.slice(0, match.index);
      if (lb.type === lb.lb.test(leftContext)) {
        // Doesn't work correctly if lookahead in regex looks outside of the match
        output +=
          str.slice(lastEnd, match.index) +
          XRegExp.replace(match[0], regex, replacement);
        lastEnd = match.index + match[0].length;
        if (!regex.global) {
          break;
        }
        pos = match.index + (match[0].length || 1);
      } else {
        pos = match.index + 1;
      }
    }
    return output + str.slice(lastEnd);
  };
})(XRegExp);

export const isAdminRoute = () =>
  window.location.pathname.split("/")[1] === "admin";

export { XRegExp };

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
