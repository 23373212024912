import {
  EyeOutlined,
  SearchOutlined,
  SyncOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Input, Layout, message, Space, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";

import useAuth from "hooks/useAuth";
import moment from "moment-timezone";
import { db, storage } from "utils/firebase";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import "./styles.less";
import confirm from "antd/lib/modal/confirm";

const SaasTranscripts = ({ baseUrl = "/user/transcripts" }) => {
  const { t } = useTranslation();
  const language = getLanguages(t);

  const { user } = useAuth();

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetched = useRef(false);

  useEffect(() => {
    if (user && !fetched.current) {
      fetched.current = true;
      setLoading(true);
      let query = db.collection("files");
      query = query.where("user", "==", user?.uid);

      query.orderBy("createdAt", "desc").onSnapshot((snapshot) => {
        setLoading(true);
        const files = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data?.createdAt?.toDate(),
            length: convertToDuration(data?.length),
            lastEdit: data?.lastEdit?.toDate() || data?.createdAt?.toDate(),
          };
        });

        setFiles(files);
        setLoading(false);
      });

      db.doc(`users/${user.uid}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
        })
        .catch((err) => {});
    }
  }, [user]);

  const convertToDuration = (value) => {
    if (isNaN(value) || !isFinite(value)) return "00:00:00";

    // calculate hours
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    // calculate minutes
    const seconds = Math.round(value);

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {language.search}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text, record, index) => {
      if (record.status === "TRANSCRIBED") {
        return (
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={baseUrl + "/" + record.id}
          >
            {record.name}
          </a>
        );
      } else {
        return <div>{record.name}</div>;
      }
    },
  });

  const columns = [
    {
      title: language.name,
      dataIndex: "name",
      width: "15%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: language.length,
      align: "center",
      dataIndex: "length",
      width: "3%",
      sorter: {
        compare: (a, b) => a.length.localeCompare(b.length),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") return <div>{record.length}</div>;
      },
    },
    {
      title: language.uploadDate,
      align: "center",
      dataIndex: "uploadDate",
      width: "5%",
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
      },
      render: (text, record, index) => (
        <div>{moment(record.createdAt).format("LL")}</div>
      ),
    },
    {
      title: language.lastEdit,
      align: "center",
      width: "5%",
      sorter: {
        compare: (a, b) => a.lastEdit - b.lastEdit,
      },
      render: (record) => (
        <div>{`${moment(record.lastEdit).format("LL")}`}</div>
      ),
    },
    {
      title: language.status,
      align: "center",
      width: "3%",
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      render: (record) => {
        switch (record.status) {
          case "TRANSCRIBING":
            return (
              <Tag icon={<SyncOutlined spin />} color="#108ee9">
                {language.transcribing}
              </Tag>
            );
          case "TRANSCRIBED":
            return (
              <a target={"_blank"} href={baseUrl + "/" + record.id}>
                <Tag color="#87d068">{language.ready}</Tag>
              </a>
            );
          case "ERROR":
            return <Tag color="#ff0000">{language.error}</Tag>;
          case "UPLOADED":
            return <Tag color="#ff9300">{language.uploaded}</Tag>;
          case "DOWNLOADING":
            return <Tag color="#ff9300">Downloading</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: language.action,
      align: "center",
      width: "2%",
      render: (record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {record.status === "TRANSCRIBED" && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={baseUrl + "/" + record.id}
              >
                <Button type="link" icon={<EyeOutlined />}>
                  {language.btnView}
                </Button>
              </a>
            )}
            <Button
              icon={<DeleteOutlined />}
              type="link"
              danger
              onClickCapture={() => {
                confirm({
                  title: language.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  onOk: async () => {
                    if (user) {
                      setLoading(true);

                      // if (record.url) {
                      //   try {
                      //     // Delete Items Action
                      //     await storage.refFromURL(record.url).delete();
                      //   } catch {
                      //     message.error(language.deleteFail);
                      //     setLoading(false);
                      //   }
                      // }
                      const batch = db.batch();

                      if (record.taskId) {
                        const taskRef = db
                          .collection("audioTasks")
                          .doc(record.taskId);
                        batch.update(taskRef, {
                          status: "DELETED",
                        });
                      }

                      {
                        // Delete subcollections
                        const snapshot = await db
                          .collection("files")
                          .doc(record.id)
                          .collection("sentences")
                          .get();

                        snapshot.forEach((doc) => {
                          batch.delete(doc.ref);
                        });
                      }

                      batch.delete(db.collection("files").doc(record.id));

                      {
                        const snapshot = await db
                          .collection("files")
                          .doc(record.id)
                          .collection("sentencesEdited")
                          .get();
                        snapshot.forEach((doc) => {
                          batch.delete(doc.ref);
                        });
                      }

                      await batch.commit();

                      message.success(language.deleteSuccess);
                      setLoading(false);
                    } else {
                      message.error(language.deleteFail);
                      setLoading(false);
                    }
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
            >
              {language.btnDelete}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24 }}>
        <Table
          rowKey={"id"}
          loading={loading}
          bordered
          columns={columns}
          dataSource={files}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
          }}
        />
      </Layout>
    </Layout>
  );
};

export default SaasTranscripts;
