import { Button, Form, Input, Popover } from "antd";
import { cloneDeep } from "lodash";
import React from "react";
import { speakerToString } from "utils/utils";

const EditSpeakerModal = ({
  children,
  speaker,
  setSpeakers,
  handleUpdateSpeakerLabelRef,
}) => {
  const [open, setOpen] = React.useState(false);
  const [form] = Form.useForm();

  return (
    <Popover
      visible={open}
      onVisibleChange={setOpen}
      title="Chỉnh sửa đại biểu"
      placement="rightTop"
      content={
        <Form
          form={form}
          onFinish={(values) => {
            const newSpeakerLabel = speakerToString(values.speaker.trim());
            if (!newSpeakerLabel) return;

            setSpeakers((speakers) => {
              const clonedSpeakers = cloneDeep(speakers);
              const speakerObj = clonedSpeakers[speaker];
              if (!speakerObj) return speakers;

              if (handleUpdateSpeakerLabelRef.current) {
                handleUpdateSpeakerLabelRef.current(speaker, newSpeakerLabel);
              }
              speakerObj.label = newSpeakerLabel;
              delete clonedSpeakers[speaker];
              clonedSpeakers[newSpeakerLabel] = speakerObj;
              return clonedSpeakers;
            });
            setOpen(false);
            form.resetFields();
          }}
        >
          <Form.Item name="speaker">
            <Input />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", margin: 0 }}>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form.Item>
        </Form>
      }
      trigger={["click"]}
    >
      {children}
    </Popover>
  );
};

export default EditSpeakerModal;
