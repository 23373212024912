import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  EditFilled,
  DeleteOutlined,
  UploadOutlined,
  BarChartOutlined,
  FileTextOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import TranscriptEditor from "components/TranscriptEditor";
import useAuth from "hooks/useAuth";
import _, { entries, groupBy, omit, orderBy, values } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { db } from "utils/firebase";
import { exportToTxtFile, speakerToString } from "utils/utils";
import CreateSpeakerModal from "./CreateSpeakerModal";
import MediaPanel from "./MediaPanel";
import "./styles.less";
import EditSpeakerModal from "./EditSpeakerModal";
import { useForm } from "antd/lib/form/Form";
import { cleanSentences } from "components/TranscriptEditor/util";

const MeetingDetail = () => {
  const { user } = useAuth();
  const { fileId } = useParams();

  const mediaRef = useRef(null);
  const playTo = useRef(null);

  const localSentences = useRef(null);
  const [sentences, setSentences] = useState(null);
  const dicts = useRef([]);
  const [speakers, setSpeakers] = useState(null);
  const [edited, setEdited] = useState(false);

  const handleUpdateSpeakerLabelRef = useRef();

  const initEditor = useRef(false);

  const [fileMetadata, setFileMetadata] = useState(null);
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);

  const [isSummary, setIsSummary] = useState(false);

  const handleSummary = async () => {
    setIsSummary(true);
    try {
      const settingRef = await db.doc("settings/test").get();
      let prompt = settingRef.data().prompt;

      localSentences.current.forEach((sentence) => {
        prompt += `\n${speakerToString(sentence.speaker)}: ${
          sentence.transcript
        }`;
      });

      const data = await fetch(
        "https://us-central1-namisense-prod.cloudfunctions.net/api/prompt",
        {
          headers: {
            "x-api-key": "RITYNOT63ghXA506jCJoZEh79FHi8ajbbE0dVxaoc",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            prompt,
            fileId,
          }),
        }
      );

      if (data.ok) {
        const res = await data.json();
        if (res.summary) {
          setFileMetadata((fileMetadata) => ({
            ...fileMetadata,
            summary: res.summary,
          }));
          message.success("Tổng hợp nội dung thành công");
        } else {
          message.error("Tổng hợp nội dung thất bại");
        }
      }
    } catch {}
    setIsSummary(false);
  };

  const [form] = useForm();

  const fetched = useRef(false);

  const handleSave = useRef();

  const debouncedSave = useCallback(
    _.debounce(() => handleSave.current(), 1000),
    []
  );

  // Fetch sentences and dicts
  useEffect(() => {
    if (!user || fetched.current) {
      return;
    }
    fetched.current = true;
    (async () => {
      try {
        // Get file metadata
        const fileRef = db.collection("files").doc(fileId);
        const fileMetadata = (await fileRef.get()).data();

        if (!fileMetadata) {
          setError("not_found");
          return;
        }

        if (fileMetadata.isEdited) {
          setEdited(true);
        }

        setFileMetadata(fileMetadata);

        let sentenceDoc = await fileRef.collection("edited").doc("user").get();

        if (sentenceDoc.exists) {
          localSentences.current = sentenceDoc.data().sentences;
        } else {
          sentenceDoc = await fileRef.collection("sentences").get();
          localSentences.current = sentenceDoc.docs.map((doc) => doc.data());
        }

        const speakers = {};
        localSentences.current.forEach((sentence) => {
          const speaker = speakerToString(sentence.speaker);
          speakers[speaker] = {
            label: speaker,
            show: true,
            unused: false,
          };
        });
        setSpeakers(speakers);
        setSentences(_.cloneDeep(localSentences.current));
      } catch (e) {
        setError(e.message);
      }
    })();
  }, [user]);

  useEffect(() => {
    const notesRef = db
      .collection("files")
      .doc(fileId)
      .collection("notes")
      .orderBy("createdAt", "desc");
    const unsub = notesRef.onSnapshot((snapshot) => {
      setNotes(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
    return () => {
      unsub();
    };
  }, []);

  if (error) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: 60,
          fontSize: 40,
          fontWeight: "bold",
        }}
        className="edit"
      >
        {error === "not_found" ? "Transcript not found!" : error}
      </div>
    );
  }

  const handleAddNote = async ({ note }) => {
    setIsSavingNote(true);
    try {
      await db.collection("files").doc(fileId).collection("notes").add({
        content: note,
        user: user.uid,
        createdAt: new Date(),
      });
      message.success("Tạo ghi chú thành công");
      form.resetFields();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSavingNote(false);
    }
  };

  const saveTranscriptionHandle = async (showMessage = true) => {
    try {
      await db.runTransaction(async (transaction) => {
        const fileDocRef = db.collection("files").doc(fileId);
        transaction.update(fileDocRef, { notes });

        const editedDocRef = fileDocRef.collection("edited").doc("user");
        const sortedNewSentences = cleanSentences(localSentences.current, true);
        transaction.set(
          editedDocRef,
          { sentences: sortedNewSentences },
          { merge: true }
        );
        transaction.update(fileDocRef, {
          isEdited: true,
        });
      });
      showMessage && message.success("Cập nhật thành công");
      setEdited(true);
    } catch (e) {
      console.log(e);
      showMessage && message.error("Cập nhật thất bại");
    }
  };

  handleSave.current = saveTranscriptionHandle;

  return (
    <div className="edit">
      <Row className="edit-row">
        <Col span={7} style={{ padding: "12px" }} className="edit-rightcol">
          <Skeleton loading={!fileMetadata}>
            {fileMetadata && (
              <>
                <div
                  style={{
                    fontWeight: 600,
                    marginBottom: 12,
                    fontSize: 15,
                  }}
                >
                  {fileMetadata.name}
                </div>
                <MediaPanel
                  duration={fileMetadata?.length}
                  ref={mediaRef}
                  playTo={playTo}
                  url={fileMetadata?.url}
                />
                {speakers && (
                  <div className="edit-video-note" direction="vertical">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label htmlFor="notes-area">
                        <Text
                          style={{
                            textTransform: "uppercase",
                            fontWeight: 500,
                            marginLeft: "11px",
                            letterSpacing: "1px",
                          }}
                        >
                          Các đại biểu
                        </Text>
                      </label>
                      <Checkbox
                        checked={values(speakers).every(
                          (speaker) => speaker.show
                        )}
                        indeterminate={
                          values(speakers).some((speaker) => speaker.show) &&
                          values(speakers).some((speaker) => !speaker.show)
                        }
                        onChange={(e) => {
                          setSpeakers((speakers) => {
                            const clonedSpeakers = _.cloneDeep(speakers);
                            values(speakers).forEach(
                              (speaker) =>
                                (clonedSpeakers[speaker.label].show =
                                  e.target.checked)
                            );
                            return clonedSpeakers;
                          });
                        }}
                      >
                        Hiển thị tất cả
                      </Checkbox>
                    </div>
                    <Divider style={{ margin: "4px" }} />

                    <div>
                      {entries(
                        groupBy(orderBy(speakers, "unused", "asc"), "unused")
                      ).map(([group, speakers]) => {
                        const unused = group === "true";
                        return (
                          <div style={{ padding: 12 }} key={group}>
                            <div style={{ marginBottom: 8 }}>
                              {!unused ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <CreateSpeakerModal setSpeakers={setSpeakers}>
                                    <Space style={{ cursor: "pointer" }}>
                                      <PlusOutlined />
                                      <Text strong>Thêm đại biểu</Text>
                                    </Space>
                                  </CreateSpeakerModal>
                                </div>
                              ) : (
                                <div>
                                  <Text strong>Unused Speakers</Text>
                                </div>
                              )}
                            </div>
                            {orderBy(speakers, "label", "asc").map(
                              (speaker) => (
                                <div
                                  key={speaker.label}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingLeft: "22px",
                                  }}
                                >
                                  <Text style={{ fontSize: "15px" }}>
                                    {speaker.label}
                                  </Text>
                                  <Space>
                                    <EditSpeakerModal
                                      handleUpdateSpeakerLabelRef={
                                        handleUpdateSpeakerLabelRef
                                      }
                                      setSpeakers={setSpeakers}
                                      speaker={speaker.label}
                                    >
                                      <Button
                                        type="text"
                                        icon={<EditFilled />}
                                      />
                                    </EditSpeakerModal>
                                    <div>
                                      {!unused && (
                                        <Checkbox
                                          checked={speaker.show}
                                          onChange={(e) => {
                                            setSpeakers((speakers) => {
                                              const clonedSpeakers =
                                                _.cloneDeep(speakers);
                                              clonedSpeakers[
                                                speaker.label
                                              ].show = e.target.checked;
                                              return clonedSpeakers;
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                    {unused && (
                                      <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                          setSpeakers((speakers) =>
                                            omit(speakers, speaker.label)
                                          );
                                        }}
                                      />
                                    )}
                                  </Space>
                                </div>
                              )
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </Skeleton>
        </Col>
        <Col
          span={12}
          className="edit-leftcol"
          style={{ position: "relative" }}
        >
          <Skeleton loading={!sentences} paragraph={{ rows: 15 }}>
            <div style={{ display: "flex" }}>
              {sentences && sentences.length > 0 ? (
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <TranscriptEditor
                    lang={fileMetadata?.lang}
                    handleUpdateSpeakerLabelRef={handleUpdateSpeakerLabelRef}
                    speakers={speakers}
                    setSpeakers={setSpeakers}
                    playTo={playTo}
                    sentences={sentences}
                    dicts={dicts.current}
                    onChange={(value) => {
                      if (!_.isEqual(localSentences.current, value)) {
                        if (initEditor.current) debouncedSave();
                        initEditor.current = true;
                      }
                      localSentences.current = _.cloneDeep(value);
                    }}
                    videoRef={mediaRef}
                  />
                </div>
              ) : (
                <div>Not found</div>
              )}
            </div>
          </Skeleton>
          <div className="navigate-container">
            <Button
              type="primary"
              onClick={() => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                ele.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                ele.scrollTo({
                  top: ele.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              <ArrowDownOutlined />
            </Button>
          </div>
        </Col>
        <Col span={5} className="edit-rightcol" style={{ padding: "20px" }}>
          <Space size="middle" direction="vertical" style={{ width: "100%" }}>
            <Button
              icon={<UploadOutlined />}
              style={{ width: "100%" }}
              disabled={!edited}
              onClick={() => {
                exportToTxtFile(
                  cleanSentences(localSentences.current),
                  `${fileMetadata?.name}`
                );
              }}
            >
              Xuất văn bản
            </Button>
            <Button
              loading={isSummary}
              onClick={handleSummary}
              style={{ width: "100%" }}
            >
              Tổng hợp báo cáo cuộc họp
            </Button>
            <Button
              type="primary"
              disabled={!fileMetadata?.summary || isSummary}
              icon={<BarChartOutlined />}
              onClick={() => {
                if (!fileMetadata?.summary) {
                  return;
                }
                const a = document.createElement("a");
                a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
                  fileMetadata.summary
                )}`;
                a.download = `${fileMetadata.name} - Tổng hợp.txt`;
                a.click();
                a.remove();
              }}
              style={{ width: "100%" }}
            >
              Xuất báo cáo tổng hợp
            </Button>
            <Button type="primary" style={{ width: "100%" }}>
              Đăng ký mẫu giọng
            </Button>
            <div>
              <Typography.Title level={4}>
                <PushpinOutlined style={{ marginRight: 6 }} />
                Ghi chú
              </Typography.Title>
              <div style={{ marginBottom: 12 }}>
                {notes.length > 0 ? (
                  notes.map((note) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                        gap: 12,
                        width: "100%",
                        marginBottom: 6,
                      }}
                    >
                      <FileTextOutlined style={{ marginTop: 6 }} />
                      <span style={{ whiteSpace: "pre-line", flex: 1 }}>
                        {note.content}
                      </span>
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined style={{ fontSize: "75%" }} />}
                        onClick={() => {
                          db.collection("files")
                            .doc(fileId)
                            .collection("notes")
                            .doc(note.id)
                            .delete();
                        }}
                      ></Button>
                    </div>
                  ))
                ) : (
                  <Space
                    align="center"
                    direction="vertical"
                    style={{ width: "100%" }}
                  >
                    <Typography.Text type="secondary">
                      Hiện không có ghi chú
                    </Typography.Text>
                  </Space>
                )}
              </div>
              <Form form={form} onFinish={handleAddNote}>
                <Form.Item name="note">
                  <Input.TextArea
                    rows={4}
                    style={{ resize: "none" }}
                    required={[
                      {
                        required: true,
                        message: "Vui lòng nhập nội dung ghi chú",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={isSavingNote}
                    htmlType="submit"
                    icon={<PlusOutlined />}
                    style={{ width: "100%" }}
                  >
                    Thêm ghi chú
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
export default React.memo(MeetingDetail);
