import { Button, Form, Input, Popover } from "antd";
import React from "react";
import { speakerToString } from "utils/utils";

const CreateSpeakerModal = ({ children, setSpeakers }) => {
  const [open, setOpen] = React.useState(false);
  const [form] = Form.useForm();

  return (
    <Popover
      visible={open}
      onVisibleChange={setOpen}
      title="Thêm đại biểu"
      placement="rightTop"
      content={
        <Form
          form={form}
          onFinish={(values) => {
            const speaker = speakerToString(values.speaker.trim());
            if (!speaker) return;

            setSpeakers((speakers) => {
              if (speakers[speaker]) return speakers;
              return {
                ...speakers,
                [speaker]: {
                  label: speaker,
                  show: true,
                  unused: true,
                },
              };
            });
            setOpen(false);
            form.resetFields();
          }}
        >
          <Form.Item name="speaker">
            <Input />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", margin: 0 }}>
            <Button type="primary" htmlType="submit">
              Thêm
            </Button>
          </Form.Item>
        </Form>
      }
      trigger={["click"]}
    >
      {children}
    </Popover>
  );
};

export default CreateSpeakerModal;
